import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import { Button, Container, PageTitle, Place } from '../components'
import styled from '@emotion/styled'
import { theme } from '../assets/theme'
import { useTranslation } from '.'

const ShrinePlaces = ({ data, pageContext: context, location }) => {
  const limit = 2
  const offset = data?.allStrapiShrineList?.edges.length
  const [currentOffset, setCurrentOffset] = React.useState(2)
  const { t } = useTranslation(context?.locale)
  return (
    <Layout
      location={location}
      meta={context?.meta}
      data={data?.strapiShrine}
      menu={context?.menu}
      locale={context?.locale}>
      <SContainer>
        <PageTitle>{data?.strapiShrine?.title}</PageTitle>
        <ContentBlock>
          {data?.allStrapiShrineList?.edges.slice(0, currentOffset).map(({ node: shrine }) => (
            <div key={shrine.id}>
              <Place shrine={shrine} />
            </div>
          ))}
        </ContentBlock>
        {currentOffset <= offset && <Button style={{ maxWidth: '189px' }} onClick={() => setCurrentOffset(currentOffset + limit)}>{t('Смотреть еще')}</Button>}
      </SContainer>
    </Layout>
  )
}

export const query = graphql`
  query ShrinePlacesTemplate($locale: String!) {
    strapiShrine(locale: { eq: $locale }) {
      title
      background {
        url
      }
    }
    allStrapiShrineList(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          title
          address
          coords
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          how_lists {
            id
            title
            itemlist {
              name
              media {
                url
                mime
              }
            }
          }
        }
      }
    }
  }
`

const SContainer = styled(Container)`
  flex-direction: column;
  padding: 4rem 0 !important;

  ${() => theme.mqMax('lg')} {
    padding: 2rem 10px !important;
  }
`

const ContentBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

export default ShrinePlaces
